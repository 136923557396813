.footer-container{
    background-color: #ca9866;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    z-index: 999; 
    margin-top: 25px;
    padding: 10px;
    overflow: hidden;
}
@media (max-height: 690px) {
    .footer-container{
      font-size: 12px;
    }
    .footer-container ul{
        display: none;
    }
  }
  @media (max-width: 490px) {
    .footer-container{
      font-size: 12px;
      display: flex;

    }
    .footer-container ul{
        display: none;
    }
    .footer-container a{
        font-weight: 700;
        font-size: 16px;
        display: flex;
        margin-top: 5px;
    }
  }
