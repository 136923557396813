@import url('http://fonts.cdnfonts.com/css/aquire');
@font-face {
  font-family: 'Aquire';
  font-style: normal;
  font-weight: 400;
  src: local('Aquire'), url('https://fonts.cdnfonts.com/s/35282/Aquire-BW0ox.woff') format('woff');
}
@font-face {
  font-family: 'Aquire';
  font-style: normal;
  font-weight: 300;
  src: local('Aquire'), url('https://fonts.cdnfonts.com/s/35282/AquireLight-YzE0o.woff') format('woff');
}
@font-face {
  font-family: 'Aquire';
  font-style: normal;
  font-weight: 700;
  src: local('Aquire'), url('https://fonts.cdnfonts.com/s/35282/AquireBold-8Ma60.woff') format('woff');
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
.home, .about, .prices, .gallery, .contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  overflow: scroll;
} 
::-webkit-scrollbar{
  display: none;
}
.backtop{
  cursor: pointer;
  color: #000;
}