.loader{
    display: flex;
    justify-content: center;
    align-items: center;
}
.background-img{
    position: absolute;
    width: 100%;
    top: 5%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
.gallery1{
    margin-top: 50px;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-width: 25%;
    -moz-column-width: 25%;
    column-width: 25%;
    padding: 0 12px;
    
}

.gallery1 .gallery-img{
    -webkit-transition: all 650ms ease;
    transition: all 650ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}
.gallery1 .gallery-img:hover{
    filter: opacity(.8);
}
.gallery1 .gallery-img:active{
    filter: opacity(.8);
}
@media (max-width: 991px) {
    .gallery1{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count:3;
    
    }
}
@media (max-width: 480px) {
    .gallery1{
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
    column-width: 50%;
    }
    .modal.open img{
        padding: 100px 0 100px;
    }
    
        .background-img{
            position: absolute;
            object-fit: cover;
            height: 100vh;
            top: 5%;
          }
}

/* Modal */

.modal{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    transition: opacity .5s ease, visibility .5s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}
.modal.open{
    
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}
.modal.open img{
   
    width: auto;
    
    height: 100vh;
   
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}
.modal.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 3rem;
    height: 3rem;
    padding: 5px;
    
    color: #fff;
    cursor: pointer;
    z-index: 1000;
}