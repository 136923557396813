@import url('http://fonts.cdnfonts.com/css/aquire');
.desktop-view{
    width: 100vw;
    background-color: #ca9866;
}
.desktop-view li a {
        padding: 20px 25px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: #ca9866;
        color:rgb(18, 18, 18);
        cursor: pointer;
        font-size: 2rem;
        
    
}
.nav-desktop {
    display: flex;  
    justify-content: flex-end;

}
.hamburger{
    display: none;
}
.navbar{
    background-color: #ca9866;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 999; 
    
}
.ht-name{
    color: #fff;
    padding: 0 10px;
    font-size: 2.5rem;
    font-family: 'Aquire', sans-serif;
    
}
.menu-bars{
    display: flex;
    position: absolute;
    right: 0;
    padding-right: 20px ;
    font-size: 2rem;
    background: none;
    
}
.nav-menu{
    background-color: #202021;
    width: 250px;
   height: 100vh;
   display: flex;
   justify-content: center;
   position: fixed;
   top: 0;
   left: -100%; 
   transition: 850ms;
   z-index: 9999;
}
.nav-menu.active{
    left: 0;
    transition: 350ms;

}
.nav-text{
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 0px 16px;
    list-style: none;
    height: 60px;
    font-family: 'Aquire', sans-serif;
}
.nav-text a{
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}
.nav-text a:hover{
    background-color: rgb(18, 18, 18);
  
}
.nav-menu-items{
    width: 100%;
}
.navbar-toggle{
    background-color: #202021;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.span-title{
    margin-left: 16px;
}
@media (max-width: 900px) {
    .ht-name{
        margin-left: -200px;
        font-size: 1rem;
    }
    .hamburger{
        display: flex;
        background-color: #ca9866;
        height: 50px;
    }
    .desktop-view{
        display: none;
    }
} 