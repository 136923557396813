.background-img{
    position: absolute;
    width: 100%;
    top: 5%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
.contact-container{
    padding: 10px 20px;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
}
hr{
    margin: 20px;
}
.contact-container h3{
    padding: 20px;
    text-align: center;
}
.contact-title{
    padding-bottom: 20px;
    text-align: center;
}
.contact-text{
    font-size: 1.5rem;
    margin-bottom: 20px;
    height: fit-content;
    font-family: 'Courier New', Courier, monospace;

}
.contact1{
    padding: 10px;
    font-size: 23px;
    
}
.by{
    margin-bottom: 20px;
}

@media (max-width: 450px) {
    .background-img{
        position: absolute;
        object-fit: cover;
        height: 100vh;
        top: 5%;
      }
      .contact-container{
        height: 150vh;
        justify-content: space-evenly;
      }
}
@media (max-height: 690px) {
    .contact-list{
        padding-top: 55px;
    }
    .contact-title{
        padding-bottom: 50px;
        overflow: hidden;
    }
    .hours{
        margin-bottom: 50px;
        overflow: hidden;
    }
    .contact1{
        margin: 0;
    }
    .contact-text{
        height: 100vh;
    }
    .contact-container{
        height: 200vh;
      }
  }